var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('leaveReport.pageTitle'),"breadcrumb":[
            { text: _vm.$t('home'), href: '/' },
            {
                text: _vm.$t('leaveReport.pageTitle'),
                active: true
            }
        ]}}),_c('ts-panel',[_c('ts-panel-wrapper',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"card border-0 bg-gray-900"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"text-gray-500"},[_c('b',[_vm._v(_vm._s(_vm.$t('disbursementList.filterCriteria')))])])]),_c('div',{staticClass:"widget-list rounded-bottom"},[_c('div',{staticClass:"mb-3 mt-3"},[_c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"form-col-label control-label required"},[_vm._v(_vm._s(_vm.$t('leaveReport.branch')))]),_c('ts-branch-filter',{class:{
                                            'is-invalid':
                                                _vm.errors.has('template_id')
                                        },attrs:{"isPlaceholder":true},on:{"filter":function (value) { return (_vm.model.branch_id = value); }}}),(_vm.errors.has('branch_id'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('branch_id'))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"mb-3 mt-3"},[_c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"form-col-label control-label required"},[_vm._v(_vm._s(_vm.$t('leaveReport.date')))]),_c('DatePicker',{staticStyle:{"width":"100%"},attrs:{"type":"daterange","placement":"bottom-end","placeholder":_vm.$t('selectDateRange'),"transfer":true,"format":"dd-MM-yyyy"},on:{"on-change":_vm.onChangeDisbursementDate},model:{value:(_vm.model.dateRange),callback:function ($$v) {_vm.$set(_vm.model, "dateRange", $$v)},expression:"model.dateRange"}})],1)]),_c('div',{staticClass:"mb-3 mt-3"},[_c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"form-col-label control-label"},[_vm._v(_vm._s(_vm.$t('leaveReport.staffName')))]),_c('Select',{class:{
                                            'is-invalid':
                                                _vm.errors.has('employee_id')
                                        },attrs:{"filterable":"","multiple":"","remote-method":_vm.loadEmployee,"clearable":true,"placeholder":_vm.$t('all')},model:{value:(_vm.model.employee_id),callback:function ($$v) {_vm.$set(_vm.model, "employee_id", $$v)},expression:"model.employee_id"}},_vm._l((_vm.employeeProfiles),function(employeeProfile,index){return _c('Option',{key:index,attrs:{"value":employeeProfile.employee_id}},[_vm._v(_vm._s(employeeProfile.employee_name_en)+"|"+_vm._s(employeeProfile.employee_name_kh)+" ")])}),1)],1)]),_c('div',{staticClass:"tw-justify-end tw-flex tw-space-x-2"},[_c('ts-button',{attrs:{"outline":"","color":"success","waiting":_vm.exporting},on:{"click":function($event){$event.preventDefault();return _vm.exportExcel.apply(null, arguments)}}},[(!_vm.exporting)?_c('i',{staticClass:"far fa-file-excel"}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('exportExcel')))]),_c('ts-button',{attrs:{"color":"danger","outline":"","waiting":_vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.preview.apply(null, arguments)}}},[(!_vm.waiting)?_c('i',{staticClass:"far fa-file-pdf"}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('previewPdf')))])],1)])])]),_c('div',{staticClass:"col-lg-9"},[_c('div',{attrs:{"id":"container"}},[_c('ts-preview-pdf',{attrs:{"src":_vm.src},model:{value:(_vm.waiting),callback:function ($$v) {_vm.waiting=$$v},expression:"waiting"}})],1)])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }